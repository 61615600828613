import $ from 'jquery';

export default function initializeSocialShare() {
	var share_buttons = $('#social-share');
	window.fbAsyncInit = function() {
		FB.init({
			appId      : '119173225353202',
			xfbml      : true,
			version    : 'v2.8'
		});
		FB.AppEvents.logPageView();
		window.FB = FB;
	};
	share_buttons.find('.facebook-share').on('click', function(e) {
		e.preventDefault();
		var url = e.target.closest('a').href;
		FB.ui({
			method: 'share',
			href: url
		}, function(response){});
	});

	(function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "//connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));

	share_buttons.find('.twitter-share').on('click', function(e) {
		e.preventDefault();
		var url = e.target.closest('a').href;
		window.open('https://twitter.com/intent/tweet?text=' + url + ' via @fellers_snider');
	});

	share_buttons.find('.print-share').on('click', function(e) {
		window.print();
	});
}
