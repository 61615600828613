// Automatically inject livereload code on a .dev domain
import $ from 'jquery';
var host = location.host.split('.');
var tld = host[host.length - 1];
if (tld == 'dev') {
  document.write(
    '<script src="http://' + (location.host || 'localhost').split(':')[0] +
    ':35729/livereload.js?snipver=1"></' + 'script>'
  );
}

var contactUsSelector = $('#contact-us');
var contactUsActiveClass = 'is-active--contact-us';
var siteNavSelector = $('#site-nav');
var siteNavActiveClass = 'is-active--site-nav';

var $window = $(window);
var win_height_padded = $window.height() * 1.1;
var isTouch = Modernizr.touch;

import 'js-polyfills/es5';

import 'js-polyfills/html';
import 'js-polyfills/dom';


// Site specific imports
import './partials/jquery-global.js';
import startNav from './partials/startNav.js';
import bindLinkWithChildrenClick from './partials/subNav.js';
import partnersSlide from './partials/partners.js';
import contactUsToggleClick from './partials/contactUsHandler.js';
import closeContact from './partials/closeContact.js';
import revealOnScroll from './partials/reveal';
import initializeSocialShare from './partials/social-share.js';

$(document).ready(function(){
  $('.nav-main__link--has-children').click(bindLinkWithChildrenClick);
  $('.contact-us-toggle').click(contactUsToggleClick);
  $('#contact-us-close').click(closeContact);
  initializeSocialShare();
  $window.on('scroll', revealOnScroll());
  startNav();
  partnersSlide();
});