export default function bindLinkWithChildrenClick(event) {
  event.preventDefault();
  var currentLinkWasActive = $(this).hasClass('is-nav-active');

  // Hide all links' children and remove all active statuses
  // $('.nav-main__list > .nav-main__list-item').children('.nav-main__sub-list').slideUp();
  $('.nav-main__link').removeClass('is-nav-active');
  $('.nav-main__sub-list').removeClass('slide-down');

  // Activate the current link, if it wasn't previously active
  if (!currentLinkWasActive) {
    $(this).addClass('is-nav-active');
    // $(this).siblings('.nav-main__sub-list').slideDown();
    $(this).siblings('.nav-main__sub-list').addClass('slide-down');
  };
};