import $ from "jquery";
import 'slick-carousel';

export default function partnersSlide() {
	$('.partners').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		slide: 'span',
		dots: false,
		arrows: true,
		prevArrow: '.partners__arrows__prev',
    nextArrow: '.partners__arrows__next',
	});
}
