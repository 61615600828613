export default function revealOnScroll(){
  var attorney = document.getElementById(".revealOnScroll");
  $(window).scroll(function(){
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      $(".revealOnScroll").addClass("reveal");
    } else if (scroll <= 100){
      $(".revealOnScroll").removeClass("reveal");
    };
  });
};