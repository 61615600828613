var contactUsSelector = $('#contact-us');
var lightOverlay = document.getElementById("light-overlay");
var contact = document.getElementById("contact-us");
  var close_contact = document.getElementById("contact-us-close");

// var contactUsActiveClass = 'is-active--contact-us';

export default function contactUsToggleClick(event) {
  event.preventDefault();
  if( contactUsSelector.hasClass('is-active--contact-us') ) {
    lightOverlay.classList.toggle('is-active--light-overlay');
    closeContactUs();
  } else {
    // startLightOverlay.openLightOverlay();
    // openContactUs();
    lightOverlay.classList.toggle('is-active--light-overlay');
  }
}

function openContactUs() {
  contactUsSelector.addClass(contactUsActiveClass);
  contactUsSelector.fadeIn();
  contactUsSelector.attr('aria-hidden', 'false');
}

function closeContactUs() {
  contactUsSelector.removeClass(contactUsActiveClass);
  contactUsSelector.fadeOut();
  contactUsSelector.attr('aria-hidden', 'true');
}